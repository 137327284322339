<template>
  <main id="main">
    <div class="wrap1160">
        <section id="guide">
          <h1><span>{{$t("GUIDE_AUCTION_H1")}}</span></h1>
          <h2>
            <span class="highlight"
              >{{$t("GUIDE_AUCTION_H2")}}</span
            >
          </h2>
          <ul class="step">
            <li class="step">
              <h3><em>STEP1</em>{{$t("GUIDE_AUCTION_H3")}}</h3>
              <div class="conts">
                <div class="outline">
                  <div class="out-txt">
                    <p>
                      {{$t("GUIDE_AUCTION_STEP1_TEXT1")}}<br><span class='highlight'>{{$t("GUIDE_AUCTION_STEP1_TEXT2")}}</span><br />{{$t("GUIDE_AUCTION_STEP1_TEXT3")}}
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li class="step">
              <h3>
                <em>STEP2</em>{{$t("GUIDE_AUCTION_STEP2_TEXT1")}}<a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME)">{{$t("GUIDE_AUCTION_COMMISSION")}}</a
                >{{$t("GUIDE_AUCTION_STEP2_TEXT2")}}
              </h3>
              <div class="conts">
                <div class="outline">
                  <div class="out-txt">
                    <p>
                      {{$t("GUIDE_AUCTION_STEP2_TEXT3")}}
                    </p>
                    <p class="mark">
                      {{$t("GUIDE_AUCTION_STEP2_TEXT4")}}
                    </p>
                    <p>
                      <a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME)">
                        {{$t("GUIDE_AUCTION_COMMISSION_ALL_SORTS")}}
                      </a>
                    </p>
                  </div>
                </div>
                <Button :text="$t('GUIDE_MYPAGE_BTN')" :addClassNameBtn="null" @child-click="$routerGo($define.PAGE_NAMES.MY_PAGE_PAGE_NAME)" class="taC btn-signup"/>
              </div>
            </li>
            <li class="step">
              <h3><em>STEP3</em>{{$t("GUIDE_AUCTION_STEP3_TEXT1")}}</h3>
              <div class="conts">
                <div class="outline">
                  <div class="out-txt">
                    <p>{{$t("GUIDE_AUCTION_STEP3_TEXT2")}}</p>
                  </div>
                </div>
              </div>
            </li>
            <li class="step">
              <h3><em>STEP4</em>{{$t("GUIDE_AUCTION_STEP4_TEXT1")}}</h3>
              <div class="conts">
                <div class="outline">
                  <div class="out-txt">
                    <p>
                      {{$t("GUIDE_AUCTION_STEP4_TEXT2")}}<a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME)"
                      >{{$t("GUIDE_AUCTION_COMMISSION")}}</a>{{$t("GUIDE_AUCTION_STEP4_TEXT3")}}
                    </p>
                    <p class="assist-out-txt">{{$t("GUIDE_AUCTION_STEP4_TEXT4")}}</p>
                  </div>
                </div>
                <ul id="tab_btn">
                  <li @click="select('1')" v-bind:class="{'active': show === '1'}" class="tab-auction">
                    <a href="#tab1"
                      >{{$t("GUIDE_AUCTION_TAB1_TITLE1")}}<br class="only_sp" />{{$t("GUIDE_AUCTION_TAB1_TITLE2")}}</a
                    >
                  </li>
                  <li @click="select('2')" v-bind:class="{'active': show === '2'}" class="tab-tender">
                    <a href="#tab2">{{$t("GUIDE_AUCTION_TAB2_TITLE1")}}</a>
                  </li>
                </ul>
                <ul id="tab_info">
                  <li v-if="show === '1'" class="tab_i-auction">
                    <h4 class="step">{{$t("GUIDE_AUCTION_TAB1_TEXT1")}}</h4>
                    <div class="dli-arrow">
                      <span class="dli-arrow-down"></span
                      ><span class="txt"
                        >{{$t("GUIDE_AUCTION_TAB1_TEXT2")}}<br />
                        {{$t("GUIDE_AUCTION_TAB1_TEXT3")}}<br />
                        {{$t("GUIDE_AUCTION_TAB1_TEXT4")}}<br />
                        {{$t("GUIDE_AUCTION_TAB1_TEXT5")}}<br />
                        <a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_DELIVERY_PAGE_NAME)">
                          {{$t("GUIDE_AUCTION_TAB1_TEXT6")}}
                        </a>
                      </span>
                    </div>
                    <h4 class="step">
                      {{$t("GUIDE_AUCTION_TAB1_TEXT7")}}<a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME)">{{$t("GUIDE_AUCTION_COMMISSION")}}</a>{{$t("GUIDE_AUCTION_TAB1_TEXT8")}}
                    </h4>
                    <div class="dli-arrow">
                      <span class="dli-arrow-down"></span>
                      <span class="txt">
                        {{$t("GUIDE_AUCTION_TAB1_TEXT9")}}<br />
                        <a @click="$routerGo($define.PAGE_NAMES.OTHER_GUIDE_COMMISSION_PAGE_NAME)">
                          {{$t("GUIDE_AUCTION_COMMISSION_ALL_SORTS")}}
                        </a>
                      </span>
                    </div>
                    <h4 class="step">{{$t("GUIDE_AUCTION_TAB1_TEXT10")}}</h4>
                    <div class="dli-arrow">
                      <span class="dli-arrow-down"></span>
                      <span class="txt"
                        >{{$t("GUIDE_AUCTION_TAB1_TEXT11")}}<br />
                        {{$t("GUIDE_AUCTION_TAB1_TEXT12")}}<span class="mark"
                          >{{$t("GUIDE_AUCTION_TAB1_TEXT13")}}</span
                        >{{$t("GUIDE_AUCTION_TAB1_TEXT14")}}<br />
                        <span class="mark"
                          >{{$t("GUIDE_AUCTION_TAB1_TEXT15")}}</span
                        ><br />
                        {{$t("GUIDE_AUCTION_TAB1_TEXT16")}}<br />
                        {{$t("GUIDE_AUCTION_TAB1_TEXT17")}}<br />
                        {{$t("GUIDE_AUCTION_TAB1_TEXT18")}}
                      </span>
                    </div>
                    <h4 class="step">{{$t("GUIDE_AUCTION_TAB1_TEXT19")}}</h4>
                    <div class="dli-arrow">
                      <span class="dli-arrow-down"></span>
                      <span class="txt"
                        >{{$t("GUIDE_AUCTION_TAB1_TEXT20")}}<br />
                        {{$t("GUIDE_AUCTION_TAB1_TEXT21")}}
                        <div class="schedule_btn">
                          <a class="btnBsc-Black btn-list" href="https://www.obm.jp/info.php?ID=4" target="_blank">{{$t("GUIDE_DOWNLOADS_BTN")}}</a>
                        </div>
                      </span>
                    </div>
                    <h4 class="step">{{$t("GUIDE_AUCTION_TAB1_TEXT22")}}</h4>
                    <div class="dli-arrow">
                      <span></span>
                      <span class="txt mark"
                        >{{$t("GUIDE_AUCTION_TAB1_TEXT23")}}
                      </span>
                    </div>
                  </li>

                  <li v-else-if="show === '2'" class="tab_i-tender">
                    <h4>{{$t("GUIDE_AUCTION_TAB2_TEXT1")}}</h4>
                    <div class="schedule">
                      <div class="schedule_txt">
                        <p>
                          {{$t("GUIDE_AUCTION_TAB2_TEXT2")}}
                        </p>
                        <Button :text="$t('GUIDE_AUCTION_TAB2_TEXT3')" :addClassNameBtn="'btn-list'" @child-click="$routerGo($define.PAGE_NAMES.GUIDE_AUCTION_SELL_PAGE_NAME)" class="schedule_btn"/>
                      </div>
                      <figure>
                        <img src="@/assets/img/auction/pct_guide_listing.png" alt="">
                      </figure>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </section>
      </div>
  </main>

</template>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
<style scoped lang="sass" src="@/assets/scss/guide.sass"></style>
<style scoped lang="sass" src="@/assets/scss/other.sass"></style>

<script>
import Button from '../../components/Guide/Parts/Button';

export default {
  data() {
    return {
      show : '1',
    }
  },
  components : {
    Button,
  },
  methods : {
    select(num) {
      this.show = num
    }
  }
}
</script>
